<template>
  <div class="about-us-page login-page reg-page">
    <section class="au-first">
      <div class="container">
        <div class="au-texts">
          <div class="row">
            <div class="col-lg-6 col-md-12">
              <div class="my-news-cards">
                <div class="news-cards">
                  <div class="n-title">{{ $t("Ro‘yhatdan o‘tish") }}</div>
                  <div class="n-sub">
                    <span>{{ $t("Ro’yhatdan o‘tgan bo’lsangiz") }} </span
                    ><span
                      ><router-link
                        :to="'/' + $i18n.locale + '/signIn'"
                        class="to-reg"
                        >{{ $t("kirish") }}
                      </router-link></span
                    ><span> {{ $t("ni bosing") }} </span>
                  </div>
                  <div v-if="!codeSent" class="wrap-signUp-form">
                    <div class="my-chekboxs" v-if="showType">
                      <div class="my-label my-label1">
                        <label class="chekcbox-con">
                          {{ $t('Turist') }}
                          <input
                            v-model="signUpType"
                            value="turist"
                            type="radio"
                            name="my-radio"
                          />
                          <span class="checkmark"></span>
                        </label>
                        <div class="for-my-label1">
                          {{ $t("Agar siz Turist bo’lsangiz:") }}
                        </div>
                      </div>
                      <div class="my-label my-label2">
                        <label class="chekcbox-con">
                          {{ $t("Cheef") }}
                          <input
                            v-model="signUpType"
                            value="cheef"
                            type="radio"
                            name="my-radio"
                          />
                          <span class="checkmark"></span>
                        </label>
                        <div class="for-my-label2">
                          {{ $t("Agar siz Cheef bo’lsangiz:") }}
                        </div>
                      </div>
                    </div>
                    <form
                      @submit.prevent="registerTurist()"
                      class="log-form"
                      v-if="signUpType == 'turist'"
                    >
                      <div class="f-login">
                        <input
                          v-model="formdata.first_name"
                          type="text"
                          required
                          :placeholder="$t('Ismingiz')"
                        />
                      </div>
                      <div class="f-login">
                        <input
                          v-model="formdata.last_name"
                          type="text"
                          required
                          :placeholder="$t('Familyangiz')"
                        />
                      </div>
                      <div class="f-login">
                        <input
                          v-model="formdata.phone"
                          type="number"
                          :placeholder="$t('Telefon raqamingiz')"
                        />
                      </div>
                      <div class="f-login">
                        <input
                          v-model="formdata.email"
                          type="email"
                          required
                          :placeholder="$t('email')"
                        />
                      </div>
                      <div class="f-login">
                        <input
                          v-model="formdata.password"
                          type="password"
                          required
                          :placeholder="$t('password')"
                        />
                      </div>
                      <div class="f-login">
                        <input
                          v-model="formdata.password_confirm"
                          type="password"
                          required
                          :placeholder="$t('password confirm')"
                        />
                      </div>
                      <p class="error-message" v-for="er in error" :key="er">
                        {{ er }}
                      </p>
                      <input
                        v-if="!regTuristLoading"
                        type="submit"
                        :value="$t('Kirish')"
                        class="more-button"
                      />
                      <loader v-else></loader>
                    </form>
                    <form class="log-form" v-if="signUpType == 'cheef'">
                      <div class="f-login">
                        <input
                          type="text"
                          :placeholder="$t('Ism familiyangiz')"
                        />
                      </div>
                      <div class="f-login">
                        <input
                          type="text"
                          :placeholder="$t('Telefon raqamingiz')"
                        />
                      </div>
                      <div class="f-login">
                        <input type="text" :placeholder="$t('Aloqa raqami')" />
                      </div>
                      <div class="f-login">
                        <div
                          class="info-2"
                          :class="{ activated: togglerMydrop }"
                        >
                          <div
                            class="my-drop-down"
                            @click="togglerMydrop = !togglerMydrop"
                          >
                            <div class="loc-img">
                              <img
                                src="../../assets/images/icons/loca.svg"
                                alt=""
                              />
                            </div>
                            {{ $t("Manzil") }}
                            <img
                              src="../../assets/images/icons/arrow-down.svg"
                              alt=""
                            />
                          </div>
                          <div class="drop-down-menu">
                            <div class="dm-content">
                              <label class="chekcbox-con"
                                >Description <input type="checkbox" />
                                <span class="checkmark"></span>
                              </label>
                              <label class="chekcbox-con"
                                >Description <input type="checkbox" />
                                <span class="checkmark"></span>
                              </label>
                              <label class="chekcbox-con"
                                >Description <input type="checkbox" />
                                <span class="checkmark"></span>
                              </label>
                              <label class="chekcbox-con"
                                >Description <input type="checkbox" />
                                <span class="checkmark"></span>
                              </label>
                              <label class="chekcbox-con"
                                >Description <input type="checkbox" />
                                <span class="checkmark"></span>
                              </label>
                              <label class="chekcbox-con"
                                >Description <input type="checkbox" />
                                <span class="checkmark"></span>
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <input
                        type="submit"
                        :value="$t('Kirish')"
                        class="more-button"
                      />
                    </form>
                  </div>
                  <div v-else class="email-code">
                    <form
                      @submit.prevent="confirmEmail()"
                      class="log-form my-log-form"
                    >
                      <label class="" for="emailCode">
                        {{ $t("Enter Confirmation code") }}
                      </label>
                      <div class="f-login">
                        <input
                          v-model="email_code"
                          required
                          type="text"
                          :placeholder="$t('enter code')"
                        />
                      </div>
                      <p class="error-message text-center">{{ errorCode }}</p>
                      <input
                        v-if="!emailLoading"
                        type="submit"
                        :value="$t('Send')"
                        class="more-button"
                      />
                      <loader v-else></loader>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-12">
              <div class="about-us" v-if="signUpType == 'turist'">
                <div class="sub" v-if="signUpTurist!==''" v-html="signUpTurist.description">
                 
                </div>
               
              </div>
              <div class="about-us" v-if="signUpType == 'cheef'">
                <div class="sub" v-if="signUpCheef!==''" v-html="signUpCheef.description">
                 
                </div>
               
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import axios from "axios";
import { mapState } from 'vuex';
export default {
  data() {
    return {
      codeSent: this.$store.state.codeSent,
      togglerMydrop: false,
      email_code: "",
      formdata: {
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
        password: "",
        password_confirm: "",
      },
      error: "",
      errorCode: "",
      regTuristLoading: false,
      emailLoading: false,
    };
  },
  computed: {
    ...mapState([
      'signUpTurist',
      'signUpCheef',
      'showType'
    ]),
    signUpType: {
      get() {
        return this.$store.state.signUpType;
      },
      set(value) {
        this.$store.commit("CHANGE_SIGNUP_TYPE", value);
      },
    },
  },
  methods: {
    registerTurist() {
      this.regTuristLoading = true;
      this.error = "";
      axios
        .post("user/register", this.formdata)
        .then(() => {
          this.regTuristLoading = false;
          this.codeSent = true;
          this.$store.commit("SENT_CODE", true);
          document.querySelector(".email-code").scrollIntoView();
          this.$toasted.show(
            this.$t("Code successfuly sent to your email please, enter code"),
            {
              duration: 5000,
              position: "top-left",
              icon: "success-icon",
            }
          );
        })
        .catch((error) => {
          this.regTuristLoading = false;
          this.error = error.response.data.errors;
          console.log(this.error);
          console.log(error.response.data.errors);
        });
    },
    confirmEmail() {
      this.errorCode = "";
      this.emailLoading = true;
      axios
        .post("user/confirm-email", {
          email: this.formdata.email,
          code: this.email_code,
        })
        .then((res) => {
          this.emailLoading = false;
          this.codeSent = false;
          this.$store.commit("SENT_CODE", true);
          this.$store.commit("USER_DATA", res.data.data);
          this.$router.push("/");
        })
        .catch((error) => {
          this.emailLoading = false;
          this.errorCode = error.response.data.message;
        });
    },
  },
  mounted(){
    this.$store.dispatch('signUpTurist')
    this.$store.dispatch('signUpCheef')
  },
  destroyed(){
    this.$store.commit('SHOW_SIGNUP_TYPE', true)
  }
};
</script>

<style>
.text-center {
  text-align: center;
}
.chekcbox-con .checkmark:after {
  width: 5px;
  height: 11px;
}
.error-message {
  font-family: Baloo2;
  font-size: 14px;
  color: #f11;
  width: 100%;
}
.my-log-form {
  flex-direction: column !important;
  align-items: center;
}
.my-log-form label {
  margin-bottom: 25px;
}
</style>